<template src="./template.html"></template>

<script>
import CreateUsers from "@/components/Users/CreateDialog/index"
import EditUsers from "@/components/Users/EditDialog/index"
export default {
  name: "UserPage",
  components: {
    CreateUsers,
    EditUsers,
  },
  data() {
    return {
      headers: [
        {
          text: '使用者編號',
          align: 'start',
          value: 'AdminID',
        },
        {
          text: '使用者名稱',
          align: 'start',
          value: 'Nickname',
        },
        {
          text: '使用者帳號',
          align: 'start',
          value: 'Account',
        },
        {
          text: '權限',
          align: 'start',
          value: 'Role',
        },
        {
          text: '狀態',
          align: 'start',
          value: 'Status',
        },
        { text: '建立日期', value: 'NewTime' },
        { text: '動作', value: 'actions', sortable: false },
      ],
      role_array: [
        { key: "A", label: "管理者" },
        { key: "E", label: "編輯者" },
        { key: "C", label: "品牌顧問" },
        { key: "V", label: "檢視者" },
      ],
      footerprops: {
        'items-per-page-text': '每頁顯示',
      },
      users: [],
    }
  },

  created() {
    this.LoadData()
  },

  methods: {
    OpenCreate() {
      this.$refs.CreateUsers.Open()
    },
    OpenEdit(item) {
      this.$refs.EditUsers.Open(item)
    },
    async LoadData() {
      this.loading = true
      let response = await this.GetData("/admin/admin")
      this.loading = false
      if (response != "error") {

        this.users = response
      }
    },
  },

  filters: {
    status(val) {
      return (val == 'Y') ? "已啟用" : "已停用";
    },
  }
}
</script>