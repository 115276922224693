<template src='./template.html'></template>

<script>
export default {
  name: "UserEdit",
  props: {
    role_array: {
      require: true,
      type: Array
    }
  },
  data() {
    return {
      dialog: false,
      valid: false,
      editedItem: {
        Status: "Y",
        Role: "A",
        Nickname: "",
        Account: "",
        Password: "",
      },
      defaultItem: {
        Status: "Y",
        Role: "A",
        Nickname: "",
        Account: "",
        Password: "",
      },
      status_array: [
        {
          label: "已啟用",
          value: "Y"
        },
        {
          label: "已停用",
          value: "N"
        }
      ],
      rules: {
        required: value => { return (value == '') ? '此欄位不得留空' : true },
      },
    }
  },
  methods: {
    Open(item) {
      this.editedItem = Object.assign({}, item)
      this.dialog = true
      this.valid = true
      this.$emit("load-data")
    },
    Close() {
      this.editedItem = Object.assign({}, this.defaultItem)
      this.dialog = false
      this.valid = true
    },
    Validate() {
      this.$refs.CustomerForm.validate()
      setTimeout(() => {
        this.valid ? this.CreateCustomer() : ""
      }, 100);
    },
    async CreateCustomer() {
      this.$store.commit("SetLoading", true)
      let response = await this.SendData("/admin/admin", "patch", { Data: this.editedItem })
      this.$store.commit("SetLoading", false)
      if (response != "error") {

        this.Close()
        this.$emit("load-data")
        this.$store.commit("SetSnackBar", { status: true, content: "已成功更新使用者" })
      }
    }
  },
}
</script>